<template>
    <div class="my-3">
        <div>
            <CRow>
                <CCol>
                    <CTabs variant="tabs" :active-tab="0">
                        <CTab :title="$t('label.transport')">
                            <CCard class="card-border">
                                <CRow class="justify-content-center px-3">
                                    <CCol sm="12" lg="6">
                                        <div class="form-group form-row" rol="group">
                                            <label class="col-form-label col-sm-12 col-lg-4 text-right" style="font-size:13px" >{{$t('label.transport')}}</label>
                                            <div class="col-sm-12 col-lg-8 input-group h-25">
                                                <v-select
                                                    id="v-select-small"
                                                    size="sm"
                                                    class="select-adjust"
                                                    v-model="VForm.Logistic.CarrierId.$model"
                                                    :options="TransportOptions"
                                                    :reduce="option => option.value"
                                                    :getOptionLabel="option => option.title"
                                                    :placeholder="$t('label.select')"
                                                    :class="VForm.Logistic.CarrierId.$dirty ? (VForm.Logistic.CarrierId.$error ? 'select-client--error' : 'select-client--correct')  : ''"
                                                    :disabled="isWasRequested"
                                                >
                                                    <template #no-options="{ }">
                                                        {{$t('label.noResultsFound')}}
                                                    </template>
                                                    <template #option="{title}">
                                                        {{ title }}<br />
                                                    </template>
                                                </v-select>
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol sm="12" lg="6"></CCol>
                                    <CCol sm="12" lg="6">
                                        <CInput
                                            size="sm"
                                            v-uppercase
                                            v-model="VForm.Logistic.SearchDriver.$model"
                                            label="CI"
                                            :addLabelClasses="'text-right'"
                                            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                            placeholder="CI"
                                            :is-valid="hasError(VForm.Logistic.DriverId)"
                                            :invalid-feedback="errorMessage(VForm.Logistic.DriverId)"
                                            :disabled="isWasRequested"
                                        >
                                            <template #prepend>
                                                <CSelect
                                                    size="sm"
                                                    v-model.trim="VForm.Logistic.numberIdLetter.$model"
                                                    :options="CiTypeOptions"
                                                    class="mb-0 mr-2"
                                                    :is-valid="hasError(VForm.Logistic.DriverId)"
                                                    :disabled="isWasRequested"
                                                />
                                            </template>
                                            <template #append>
                                                <div class="d-flex align-items-start">
                                                <CButton
                                                    color="watch"
                                                    square
                                                    size="sm"
                                                    class="mr-1"
                                                    style="padding: 0.15rem 0.4rem;"
                                                    @click="getDriverByCi"
                                                    :disabled="isWasRequested"
                                                >
                                                    <CIcon name="cil-search"/>
                                                </CButton>
                                                <CButton
                                                    v-if="Object.keys(diverSelected).length>1"
                                                    color="edit"
                                                    square
                                                    size="sm"
                                                    style="padding: 0.15rem 0.4rem;"
                                                    @click="(isEditMaster=true, ModalNewDriver=true)"
                                                    :disabled="isWasRequested"
                                                >
                                                    <CIcon name="pencil" />
                                                </CButton>
                                                </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12" lg="6">
                                        <CInput
                                            size="sm"
                                            v-uppercase
                                            v-model="DriverName"
                                            :label="$t('label.driver')"
                                            :addLabelClasses="'text-right'"
                                            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                                            :placeholder="$t('label.driver')"
                                            :is-valid="hasError(VForm.Logistic.DriverId)"
                                            :invalid-feedback="errorMessage(VForm.Logistic.DriverId)"
                                            :disabled="true"
                                        />
                                    </CCol>
                                    <CCol sm="12" lg="6">
                                        <CInput
                                            size="sm"
                                            v-uppercase
                                            v-model="VForm.Logistic.SearchPlate.$model"
                                            :label="$t('label.plate')"
                                            :addLabelClasses="'text-right'"
                                            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                            :placeholder="$t('label.plate')"
                                            :is-valid="hasError(VForm.Logistic.VehicleId)"
                                            :invalid-feedback="errorMessage(VForm.Logistic.VehicleId)"
                                            :disabled="isWasRequested"
                                        >
                                            <template #append>
                                                <div class="d-flex align-items-start">
                                                <CButton
                                                    color="watch"
                                                    square
                                                    size="sm"
                                                    class="mr-1"
                                                    style="padding: 0.15rem 0.4rem;"
                                                    @click="getVehicleByLicensePlate"
                                                    :disabled="isWasRequested"
                                                >
                                                    <CIcon name="cil-search"/>
                                                </CButton>
                                                <CButton
                                                    v-if="Object.keys(objectVehicle).length>1"
                                                    color="edit"
                                                    square
                                                    size="sm"
                                                    style="padding: 0.15rem 0.4rem;"
                                                    @click="(isEditMaster=true, ModalNewVehicle=true)"
                                                    :disabled="isWasRequested"
                                                >
                                                    <CIcon name="pencil" />
                                                </CButton>
                                                </div>
                                            </template>
                                        </CInput>
                                    </CCol>
                                    <CCol sm="12" lg="6">
                                        <CInput
                                            size="sm"
                                            v-uppercase
                                            v-model="VehicleName"
                                            :label="$t('label.vehicle')"
                                            :addLabelClasses="'text-right'"
                                            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                                            :placeholder="$t('label.vehicle')"
                                            :is-valid="hasError(VForm.Logistic.VehicleId)"
                                            :invalid-feedback="errorMessage(VForm.Logistic.VehicleId)"
                                            :disabled="true"
                                        />
                                    </CCol>
                                </CRow> 
                            </CCard>
                        </CTab>
                    </CTabs>
                </CCol>
            </CRow>
        </div>
        <ModalVehicleOrDriver
            :items="VehicleOrDriverList"
            :Type="TypeList"
            @SelectedInfo="SelectedInfo"
            @CloseModal="(VehicleOrDriverList=[], TypeList=0)"
        />

        <ModalDriver
            :modal="ModalNewDriver"
            :diverSelected="diverSelected"
            :CallMaster="true"
            :editModal="isEditMaster"
            :title="isEditMaster ? `${$t('label.edit')} ${$t('label.driver')} ${diverSelected?.DriverCi??''}` : `${$t('label.nuevo')} ${$t('label.driver')}`"
            @UpdatedDriver="getDriver"
            @updated-modal="(ModalNewDriver=false, isEditMaster=false)"
        />

        <ModalVehiculo
            :modal="ModalNewVehicle"
            :objectVehicle="objectVehicle"
            :CallMaster="true"
            :editModal="isEditMaster"
            :title="isEditMaster ? `${$t('label.edit')} ${$t('label.vehicle')} ${objectVehicle?.LicensePlate??''}` : `${$t('label.nuevo')} ${$t('label.vehicle')}`"
            @UpdatedVehicle="getVehicle"
            @updated-modal="(ModalNewVehicle=false, isEditMaster=false)"
        />
    </div>
</template>
<script>
    import UpperCase  from '@/_validations/uppercase-directive';
    import { mapState } from 'vuex';
    import General from '@/_mixins/general';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';
    import ModalVehicleOrDriver from '@/pages/yard-management/modal-vehicle-or-driver-list';
    import ModalDriver from '@/pages/choferesvehiculos/chofer/modal-chofer'
    import ModalVehiculo from '@/pages/choferesvehiculos/vehiculo/modal-vehiculo'
    
    //data
    function data() {
        return {
            //General
            diverSelected: {},
            DriverName: '',
            VehicleOrDriverList: [],
            TypeList: 0,
            isEditMaster: false,
            VehicleName: '',
            ModalNewDriver: false,
            ModalNewVehicle: false,
            objectVehicle: {},
            TransportList: []
        };
    }

    //-----------------------   Method    ------------------------

    function getVehicle(Vehicle) {
        let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1) ?? '';
        this.ModuleForm.SearchPlate = Vehicle.LicensePlate;
        this.ModuleForm.VehicleId = Vehicle.VehicleId;
        this.VehicleName = `${Vehicle['TpVehicleName'+_lang] ?? 'N/A'} - ${Vehicle['VehicleClassifName'+_lang] ?? 'N/A'}`; 
        this.objectVehicle = Vehicle;
    }

    function getDriver(Driver) {
        this.ModuleForm.numberIdLetter = Driver.DriverCi?.charAt(0) ?? '';
        this.ModuleForm.SearchDriver = Driver?.DriverCi?.slice(1) ?? '';
        this.ModuleForm.DriverId = Driver.DriverId;
        this.DriverName = Driver.DriverName;
        this.diverSelected = Driver;
    }

    function SelectedInfo(info, Type) {
        if (Type == 1) {
            let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1) ?? '';
            this.Form.Logistic.SearchPlate = info.LicensePlate;
            this.Form.Logistic.VehicleId = info.VehicleId;
            this.VehicleName = `${info['TpVehicleName'+_lang] ?? 'N/A'} - ${info['VehicleClassifName'+_lang] ?? 'N/A'}`; 
            this.objectVehicle = info;
        }else{
            this.numberIdLetter = info.DriverCi?.charAt(0) ?? '';
            this.Form.Logistic.SearchDriver = info?.DriverCi?.slice(1) ?? '';
            this.DriverId = info.DriverId;
            this.DriverName = info.DriverName;
            this.diverSelected = info;
        }
    }

    async function getService() {
        if(this.TransportList.length == 0){
            this.$store.state.yardManagement.loading = true;

            (this.TransportList.length == 0) && await this.ClientListByActivity();

            this.$store.state.yardManagement.loading = false;

        }
    }

    async function getData() {
        let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1) ?? '';
        
        this.Form.Logistic.CarrierId = this.ContainerItem.CarrierId;

        this.Form.Logistic.numberIdLetter = this.ContainerItem?.DriverCi?.charAt(0) ?? '';
        this.Form.Logistic.SearchDriver = this.ContainerItem?.DriverCi?.slice(1) ?? '';
        this.DriverName =  this.ContainerItem?.DriverName;
        this.Form.Logistic.DriverId = this.ContainerItem.DriverId;

        this.Form.Logistic.SearchPlate =  this.ContainerItem.LicensePlate;
        this.VehicleName = `${this.ContainerItem['TpVehicleName'+_lang] ?? 'N/A'} - ${this.ContainerItem['VehicleClassifName'+_lang] ?? 'N/A'}`;
        this.Form.Logistic.VehicleId = this.ContainerItem.VehicleId;
    }

    async function getDriverByCi() {
        if (this.ModuleForm.SearchDriver.length <= 10) {
            this.$store.state.yardManagement.loading = true;
            await this.$http.get('Driver-by-Ci', { DriverCi: (this.ModuleForm.numberIdLetter+this.ModuleForm.SearchDriver) })
            .then(response => {
                let List = response.data.data;
                if (List&&List.length!=0) {
                    if (List.length==1) {
                        this.ModuleForm.numberIdLetter = List[0]?.DriverCi?.charAt(0) ?? '';
                        this.ModuleForm.SearchDriver = List[0].DriverCi?.slice(1) ?? '';
                        this.ModuleForm.DriverId = List[0].DriverId;
                        this.DriverName = List[0].DriverName;
                        this.diverSelected = List[0];
                    }
                    else{
                        this.TypeList = 2;
                        this.ModuleForm.DriverId = '';
                        this.DriverName = '';
                        this.diverSelected = {};
                        this.VehicleOrDriverList = List;
                    }
                }
                else{
                    this.ModuleForm.DriverId = '';
                    this.DriverName = '';
                    this.diverSelected = {DriverCi: (this.ModuleForm.numberIdLetter+this.ModuleForm.SearchDriver)};
                    this.ModalNewDriver = true;
                }
            })
            .catch( err => {
                this.ModuleForm.DriverId = '';
                this.DriverName = '';
                this.diverSelected = {};
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(()=> {
                this.$store.state.yardManagement.loading = false;
            });
        }else{
            this.ModuleForm.DriverId = '';
            this.DriverName = '';
            this.diverSelected = {};
            this.VehicleOrDriverList = [];
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: `${this.$t('validation.max')} ${10} ${this.$t('validation.chars')}`,
            type: "error"
            });
        }
    }

    async function getVehicleByLicensePlate() {
        if (this.ModuleForm.SearchPlate&&this.ModuleForm.SearchPlate.length <= 10) {
            this.$store.state.yardManagement.loading = true;
            await this.$http.get('Vehicle-by-LicensePlate', { LicensePlate: this.ModuleForm.SearchPlate })
            .then(response => {
                let List = response.data.data;
                if (List&&List.length!=0) {
                if (List.length==1) {
                    let Vehicle = List[0];
                    let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1) ?? '';
                    this.ModuleForm.SearchPlate = Vehicle.LicensePlate;
                    this.ModuleForm.VehicleId = Vehicle.VehicleId;
                    this.VehicleName = `${Vehicle['TpVehicleName'+_lang] ?? 'N/A'} - ${Vehicle['VehicleClassifName'+_lang] ?? 'N/A'}`;
                    this.objectVehicle = List[0];
                }else{
                    this.TypeList = 1;
                    this.ModuleForm.VehicleId = '';
                    this.VehicleName = '';
                    this.objectVehicle = {};
                    this.VehicleOrDriverList = List;
                }
                }else{
                this.ModuleForm.VehicleId = '';
                this.VehicleName = '';
                this.objectVehicle = { LicensePlate: this.ModuleForm.SearchPlate };
                this.ModalNewVehicle = true;
                }
            })
            .catch( err => {
                this.ModuleForm.VehicleId = '';
                this.VehicleName = '';
                this.objectVehicle = {};
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(()=> {
                this.$store.state.yardManagement.loading = false;
            });
        }else{
            this.TypeList = 1;
            this.ModuleForm.VehicleId = '';
            this.VehicleName = '';
            this.objectVehicle = {};
            this.VehicleOrDriverList = [];
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: `${this.$t('validation.max')} ${10} ${this.$t('validation.chars')}`,
                type: "error"
            });
        }
    }

    async function ClientListByActivity() {
        await this.$http.get('Client-list-by-activity', { TpClientId: process.env.VUE_APP_TP_CLIENT_LAND_TRANSPORT, Filter: 'ACTIVO' })
            .then(response => {
                let List = response.data.data;
                this.TransportList = List&&List.length!=0 ? List : [];
            })
            .catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            })
    }


    //-----------------------   Computed   ------------------------
    
    function CiTypeOptions() {
        return [
            {
                value: "V",
                label: "V",
            },
            {
                value: "P",
                label: "P",
            },
            {
                value: "E",
                label: "E",
            },
            {
                value: "J",
                label: "J",
            },
        ];
    }

    function TransportOptions() {
        if(this.TransportList.length === 0){
            return [{
            title: '', 
            value: this.$t('label.select'),
            }];
        }else{
            let chart = [{
            title: this.$t('label.select'), 
            value: '',
            }]
            this.TransportList.map(function(e){
            chart.push({
                title: e.ClientAlias,
                value: e.ClientTpId,
                Json: {
                        ...e
                    }
            })
            })
            return chart;
        }
    }


    export default{
        name: 'bl-logistic',
        data,
        mixins: [General],
        directives: UpperCase,
        props: {
            Form: {
                type: Object,
                required: true,
                default: () => {},
            },
            ModuleForm: {
                type: Object,
                required: true,
                default: () => {},
            },
            ContainerItem: {
                type: Object,
                required: true,
                default: () => {},
            },
            isEdit: Boolean,
            Tab: Boolean,
            Active: Boolean,
            isWasRequested:Boolean,
            VForm: Object
        },
        components: {
            ModalVehicleOrDriver,
            ModalDriver,
            ModalVehiculo
        },
        methods: {
            getService,
            getDriverByCi,
            getDriver,
            SelectedInfo,
            getVehicleByLicensePlate,
            getVehicle,
            ClientListByActivity,
            getData
        },
        computed: {
            CiTypeOptions,
            TransportOptions,
            ...mapState({
                CompanyBranchId: state => state.auth.branch.CompanyBranchId,
                YardId: state => state.yardManagement.yardData.YardId
            })
        },
        watch:{
            Tab: async function (Newval) {
                if (Newval) {
                    await this.getService();
                }
            },
            isWasRequested: async function (Newval) {
                if (Newval) {
                    this.diverSelected = {};
                    this.DriverName = '';
                    this.VehicleOrDriverList = [];
                    this.TransportList = [];
                    this.TypeList = 0;
                    this.isEditMaster = false;
                    this.VehicleName = '';
                    this.ModalNewDriver = false;
                    this.ModalNewVehicle = false;
                    this.objectVehicle = {};
                    setTimeout(() => { this.VForm.Logistic.$reset(); }, 10);
                }
            },
            Active: async function (Newval) {
                if (!Newval) {
                    this.diverSelected = {};
                    this.DriverName = '';
                    this.VehicleOrDriverList = [];
                    this.TransportList = [];
                    this.TypeList = 0;
                    this.isEditMaster = false;
                    this.VehicleName = '';
                    this.ModalNewDriver = false;
                    this.ModalNewVehicle = false;
                    this.objectVehicle = {};
                }
                else{
                    if (this.isEdit) 
                        this.getData();  

                    setTimeout(() => { this.VForm.Logistic.$reset(); }, 10);
                }
                    
            }
        }
    }
</script>