<template>
    <div>
        <CCollapse :show="activeItem(1)">
            <BlReception/>
        </CCollapse>
        <CCollapse :show="activeItem(2)">
            <BlPositioning/>
        </CCollapse>
        <CCollapse :show="activeItem(3)">
            <!--<Dispatch/>-->
        </CCollapse>
        
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import BlReception from './reception/bl-reception-index';
    import BlPositioning from './positioning/bl-positioning-index';
    
    //methods
    function activeItem(item) {
        return this.dropBlMovement === item;
    }
    export default {
        name:"movements-index",
        methods:{
            activeItem,
        },
        components:{
            BlReception,
            BlPositioning
        },
        computed:{
            ...mapState({
                tabIndex: state => state.yard-management.tabMovementIndex,
                dropBlMovement: state => state.yardManagement.dropBlMovement,
            })
        },
        watch:{
            //tabIndex
        }
    }
</script>